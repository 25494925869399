import React from "react";

import one from '../../assets/images/one.webp'
import two from '../../assets/images/two.webp'
import three from '../../assets/images/three.webp'


export default function SectionOne() {
  return (
    <div>
      <section className="sectione">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 text-start align-items-center align-content-center align-self-center" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="150" >
              <h1
                className="text-break text-start huge"
              
              >
             Transforming ideas into solutions with cutting-edge software development.
              </h1>
              <h2 className="text-break text">
                
              Algofact is a company that introduces users to the digital world, creates, develops and supports software using cutting-edge technology to develop large, medium and small businesses.
                
                
                 </h2>
            </div>
            <div className="col-sm-6">
              <div className="vstack">
                <div data-aos="fade-down" data-aos-duration="1000" data-aos-delay="150" className="left-image">
                  <img
                    className="img-fluid sec-one-images sec-divs"
                    src={one}
                    alt='landingleftphoto'
                  />
                </div>
                <div className="text-end right-image" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="250" >
                  <img
                    className="img-fluid sec-one-images sec-divs"
                    src={two}
                    alt='landingphoto'
                  />
                </div>
                <div className="left-image-bottom" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="350">
                  <img
                    className="img-fluid sec-one-images sec-divs"
                    src={three}
                    alt='lendingrightphoto'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
