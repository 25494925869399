import { createContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader from '../general/Loader'

const GeneralContext = createContext();

export default GeneralContext;

export const GeneralProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);
  const [sidebarValurDelay, setSidebarValurDelay] = useState(false);


  const refSide = useRef(null);

  const history = useNavigate();

  let closeWhenNavigate = ()=>{
    setSidebar(sidebar ? false : true);
    setSidebarValurDelay(sidebarValurDelay ? false : true);
  }

  let sidebarDelay = () => {
    setSidebar(sidebar ? false : true);
    setTimeout(() => {
      setSidebarValurDelay(sidebarValurDelay ? false : true);
    }, 200);
  };

  let sidebarDelayClose = () => {
    setSidebarValurDelay(sidebarValurDelay ? false : true);
    setTimeout(() => {
      setSidebar(sidebar ? false : true);
    }, 400);
  };

  let contactServices = async (data) => {
    let response = await fetch("https://jellyfish-app-ouxbu.ondigitalocean.app/api/contact-algofact", {
        headers:{
            "Content-Type": "application/json"
        },
      method: "POST",
      body: JSON.stringify(data),
    });
    return response.json()
  };

  let contextData = {
    history: history,
    refSide: refSide,
    sidebar: sidebar,
    sidebarDelay: sidebarDelay,
    sidebarValurDelay: sidebarValurDelay,
    sidebarDelayClose: sidebarDelayClose,
    contactServices:contactServices,
    closeWhenNavigate:closeWhenNavigate
  };

  return (
    <GeneralContext.Provider value={contextData}>
      { children}
    </GeneralContext.Provider>
  );
};
