import React, { memo, useContext, useRef } from "react";
import whitelogo from "../assets/images/whitelogo.svg";
import GeneralContext from "../provider/GeneralProvider";
import SidebarS from "./Sidebar";

export default function Navbar() {
  const { sidebarDelay, history } = useContext(GeneralContext);

  const Image = memo(function Image({ src }) {
    return <img className="img-fluid" src={src} id="logonav" alt='logo' />;
  });
  return (
    <div>
      <SidebarS />

      <header className="z1">
        <nav className="navbar navbar-light navbar-expand-md  py-3 fixed-top d-flex justify-content-between">
          <div className="container d-flex justify-content-between">
            <ul className="navbar-nav ">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="#"
                  style={{ margin: "0" }}
                  onClick={() => sidebarDelay()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-32 0 512 512"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                  >
                    <path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"></path>
                  </svg>
                </a>
              </li>
            </ul>
            <a
              className="navbar-brand mx-auto"
              href="#"
              onClick={() => history("/")}
            >
             <Image src={whitelogo}/>
            </a>
            <a
              href="#"
              onClick={() => history("/contact")}
              className="contact-button btn"
              type="click"
              style={{ color: "white!important" }}
            >
              Contact Us
            </a>
          </div>
        </nav>
      </header>

      <nav
        id="navbar-menu"
        className="navbar d-flex justify-content-center navbar-expand-md py-3 fixed-top z1"
      >
        <ul className="navbar-nav flex-row ">
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={() => history("/")}>
              Home Page
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={() => history("/about-us")}
            >
              About Us
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={() => history("/services")}
            >
              Services
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
