import React from 'react'
import Footer from '../../general/Footer';
import Navbar from '../../general/Navbar';
import ServicesTab from '../../general/ServicesTab';
import SectionFive from './SectionFive';
import SectionFour from './SectionFour';
import SectionOne from './SectionOne';
import SectionThree from './SectionThree';



 function HomePage() {
  return (
    <div>
      <Navbar/>

      <SectionOne/>
      <ServicesTab/>
      <SectionThree/>
      <SectionFour/>
      <SectionFive/>

      <Footer/>


    </div>
  )
}

export default HomePage;