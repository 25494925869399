import React from "react";
import ServicesTab from "../../general/ServicesTab";
import Navbar from "../../general/Navbar";
import Footer from "../../general/Footer";


import about from "../../assets/images/about.webp";
import logofull from "../../assets/images/logofull.webp";
import { motion } from "framer-motion";

export default function AboutUs() {
  return (
    <motion.div
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration:3
      }}
    >
      <div>
        <Navbar />
        <section data-aos="fade-down" data-aos-duration="1000" data-aos-delay="150" data-aos-once="true">
          <div
            className="backabout"
            style={{
              backgroundImage: `url(${about})`,
              height: "600px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="container text-center h-100 position-relative headrabout">
            <div className="row text-center">
              <div className="col text-center">
                <div className="card text-center aboutcard">
                  <div className="card-body text-center">
                    <h1 className="d-md-flex justify-content-md-center abouttitle">
                      Who we are
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="aboutusp">
                  <h2 className="text-start">
                    <span style={{ color: "rgb(33, 37, 41)" }}>
                      Algofact is a company that introduces users to the digital
                      world, creates, develops and supports software using
                      cutting-edge technology to develop large, medium and small
                      businesses.We work with clients across all industries
                      including retail, manufacturing, food &amp; beverage,
                      banking, insurance and many more.
                    </span>
                    <br />
                  </h2>
                  <p className="text-start text-about text-justify">
                       All of our apps are developed using the best practices to
                    ensure the stability and scalability of latest technology,
                    include a wide range of features needed for any project. 
                    Our team handles all aspects of project management and
                    design as well as development ensuring that your project is
                    completed on time and within budget.We specialize in
                    building tools, apps and cutting edge technology. Our job
                    isn’t just installing a product but sustaining it. We listen
                    to your business needs and design solutions that help grow
                    your business. We aim to open the doors of the digital world
                    for businesses and to increase the success of businesses
                    with the latest technology products.
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-md-6 d-md-flex justify-content-md-center align-items-md-center">
                <img className="img-fluid" src={logofull} />
              </div>
            </div>
          </div>
        </section>

        <ServicesTab />

        <section className="home-team">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col col-md-8">
                <div className="sectionTitle text-center">
                  <h2>Our Team (Soon) </h2>
                </div>
              </div>
            </div>
            <div className="row blur">
              <div className="col-md-6 col-lg-3">
                <div className="card card-style2 team-card">
                  <div className="card_img">
                    <img
                      className="img-fluid img-full loaded"
                      data-original="img/team1.jpg"
                      data-was-processed="true"
                      src="team1.jpg"
                      alt="Team one"
                    />
                    <div className="hover-overlay effect-scale">
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-block">
                    <h4 className="card-title">
                      <a href="http://smarty.co.ke/templates/team/#">
                        Julia Robertson
                      </a>
                    </h4>
                    <span>Co - Founder</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card card-style2 team-card">
                  <div className="card_img">
                    <img
                      className="img-fluid img-full loaded"
                      data-original="img/team1.jpg"
                      data-was-processed="true"
                      src="team2.jpg"
                      alt="Team one"
                    />
                    <div className="hover-overlay effect-scale">
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-block">
                    <h4 className="card-title">
                      <a href="http://smarty.co.ke/templates/team/#">
                        Charles Robert
                      </a>
                    </h4>
                    <span>Co - Founder</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card card-style2 team-card">
                  <div className="card_img">
                    <img
                      className="img-fluid img-full loaded"
                      data-original="img/team1.jpg"
                      data-was-processed="true"
                      src="team3.jpg"
                      alt="Team one"
                    />
                    <div className="hover-overlay effect-scale">
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-block">
                    <h4 className="card-title">
                      <a href="http://smarty.co.ke/templates/team/#">
                        Julia Robertson
                      </a>
                    </h4>
                    <span>Co - Founder</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card card-style2 team-card">
                  <div className="card_img">
                    <img
                      className="img-fluid img-full loaded"
                      data-original="img/team1.jpg"
                      data-was-processed="true"
                      src="team4.jpg"
                      alt="Team one"
                    />
                    <div className="hover-overlay effect-scale">
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a className="overlay_icon" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-block">
                    <h4 className="card-title">
                      <a href="http://smarty.co.ke/templates/team/#">
                        Eddy Robertson
                      </a>
                    </h4>
                    <span>Co - Founder</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h4 className="text-center">Head  Quarter - ADANA</h4>
          <iframe
            allowFullScreen
            frameBorder={'0'}
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCdb_NUIiFiJU0rcrDJ0UyX3co2cQzuHTI&amp;q=Adana+%C3%87%C4%B1narl%C4%B1+Mah.&amp;zoom=12"
            width="100%"
            height="400"
          ></iframe>
        </section>

        <section>
          <h4 className="text-center">ANKARA OFFICE - SOON</h4>
          <iframe
            allowFullScreen
            frameBorder={'0'}
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCdb_NUIiFiJU0rcrDJ0UyX3co2cQzuHTI&amp;q=Ankara&amp;zoom=12"
            width="100%"
            height="400"
          ></iframe>
        </section>

        <Footer />
      </div>
    </motion.div>
  );
}
