import { AnimatePresence } from "framer-motion";
import {
  BrowserRouter as Router,
  Route,
  Routes,

} from "react-router-dom";
import AboutUs from "./pages/aboutus/AboutUs";
import Career from "./pages/career/Career";
import Contact from "./pages/contact/Contact";
import HomePage from "./pages/homepage/HomePage";
import Services from "./pages/servicess/Services";
import { GeneralProvider } from "./provider/GeneralProvider";

function App() {
  return (
    <div className="App">
      <Router>
        <GeneralProvider>
          <AnimatePresence>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/career" element={<Career />} />
              <Route path="/freelance-partner" element={<HomePage />} />
              <Route path="/project-partner" element={<HomePage />} />
            </Routes>
          </AnimatePresence>
        </GeneralProvider>
      </Router>
    </div>
  );
}

export default App;
