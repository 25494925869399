import React from 'react'
import data from '../assets/images/data.webp'
import design from '../assets/images/design.webp'
import develop from '../assets/images/develop.webp'
import project from '../assets/images/project.webp'


export default function ServicesTab() {
  return (
    <div>

<section>
    <section className="py-4 py-xl-5">
        <div className="text-white border rounded-0 border-0 d-xl-flex justify-content-xl-center back-color align-items-xl-center p-4 p-md-5" >
            <h2 className="fw-bold text-center text-white mb-0 text-style">Services</h2>
        </div>
        <div className="container py-4 py-xl-5">
            <div className="row">
                <div className="col-md-3">
                    <div className="card card-services">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col d-xl-flex align-items-xl-center">
                                    <div className="bs-icon-md bs-icon-rounded bs-icon-primary d-xl-flex justify-content-xl-center align-items-xl-center d-inline-block mb-3 bs-icon" >
                                        
                                        <img src={project} width='100'  alt='projecticon'/>
                                        
                                    </div>
                                </div>
                                <div className="col d-xl-flex align-items-xl-center">
                                    <h3>Project Management</h3>
                                </div>
                            </div>
                            <p className="card-text">We become your project support partner with our team and raise the quality standards of the product you intend to produce.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-services">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col d-xl-flex align-items-xl-center">
                                    <div className="bs-icon-md bs-icon-rounded bs-icon-primary d-xl-flex justify-content-xl-center align-items-xl-center d-inline-block mb-3 bs-icon" >
                
                                        <img src={design} width='100' alt='designicon' />
                                        </div>
                                </div>
                                <div className="col d-xl-flex align-items-xl-center">
                                    <h3>Design</h3>
                                </div>
                            </div>
                            <p className="card-text">We analyze the customer in the industry and design professional application interfaces for you. </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-services">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col d-xl-flex align-items-xl-center">
                                    <div className="bs-icon-md bs-icon-rounded bs-icon-primary d-xl-flex justify-content-xl-center align-items-xl-center d-inline-block mb-3 bs-icon" >
                                        
                                    <img src={develop} width='100' alt='developicon'/>
                                   
                                        
                                        
                                        </div>
                                </div>
                                <div className="col d-xl-flex align-items-xl-center">
                                    <h3>Development</h3>
                                </div>
                            </div>
                            <p className="card-text">As a software development company, we are dedicated to helping our clients build innovative and powerful applications that solve real-world problems. </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">

                    <div className="card card-services">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col d-xl-flex align-items-xl-center">
                                    <div className="bs-icon-md bs-icon-rounded bs-icon-primary d-xl-flex justify-content-xl-center align-items-xl-center d-inline-block mb-3 bs-icon" >
                                    <img src={data} width='100' alt='datacentericon' />
                                        </div>
                                </div>
                                <div className="col d-xl-flex align-items-xl-center">
                                    <h3>Data Center</h3>
                                </div>
                            </div>
                            <p className="card-text">We provide cutting-edge data center and data visualization services to help your business make informed decisions. </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
</section>




    </div>
  )
}
