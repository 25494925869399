import React, { useContext } from 'react'
import back from '../../assets/images/back.webp'
import logoonly from '../../assets/images/logoonly.svg'
import GeneralContext from '../../provider/GeneralProvider';


export default function SectionFive() {
  const {history} = useContext(GeneralContext);

  return (
    <div>



<section>
    <div className="container back" style={{marginBottom:'20px'}}>
        <div className="row text-center" style={{backgroundImage:`url(${back})`,backgroundPosition:'center',backgroundSize:'cover'}}>
            <div className="col-6 text-center d-md-flex justify-content-md-center align-items-md-center back-col">
                <div>
                    <h3 className="contact">Your Technology Partner </h3>
                    <h2 className="display-4 contact text-style" >Innovative Software Solutions</h2>
                    <a className="contact-button special-contact" href='#' onClick={()=>history('/contact')}>Contact Us</a>
                </div>
            </div>

            <div className="col-6 text-center d-flex justify-content-center align-items-center ">
               <img className='img-fluid' width='500' src={logoonly} alt='logoonly' />
            </div>
        </div>
    </div>
</section>
    </div>
  )
}
