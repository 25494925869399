import React, { useContext, useState } from "react";
import Footer from "../../general/Footer";
import Navbar from "../../general/Navbar";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import GeneralContext from "../../provider/GeneralProvider";
import { json } from "react-router-dom";
import { Dimmer, Message } from "semantic-ui-react";
import Loader from "../../general/Loader";

export default function Contact() {
  const [phone, setPhone] = useState();
  const { contactServices } = useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageSucces, setMessageSucces] = useState(false);
  const [fillColor, setFillColor] = useState("#37414f");

  let handleForm = async (e) => {

    e.preventDefault();
    let listofdata = [];

    let nameValue =
      e.target.name.value == null || e.target.name.value == "" ? false : true;
    let emailValue =
      e.target.email.value == null || e.target.email.value == "" ? false : true;
    let messageValue =
      e.target.message.value == null || e.target.message.value == ""
        ? false
        : true;
    let phoneValue = phone == null || phone == "" ? false : true;

    if (nameValue && emailValue &&  messageValue && phoneValue) {
      setLoading(true);
      e.target.website.checked
        ? listofdata.push("website,")
        : listofdata.forEach((element) => {
            if (element == "website,") {
              var index = listofdata.indexOf(element);
              if (index !== -1) {
                listofdata.splice(index, 1);
              }
            }
          });
      e.target.webapp.checked
        ? listofdata.push("website,")
        : listofdata.forEach((element) => {
            if (element == "website,") {
              var index = listofdata.indexOf(element);
              if (index !== -1) {
                listofdata.splice(index, 1);
              }
            }
          });

      e.target.uxdesign.checked
        ? listofdata.push("uxdesign,")
        : listofdata.forEach((element) => {
            if (element == "uxdesign,") {
              var index = listofdata.indexOf(element);
              if (index !== -1) {
                listofdata.splice(index, 1);
              }
            }
          });

      e.target.project.checked
        ? listofdata.push("project,")
        : listofdata.forEach((element) => {
            if (element == "project,") {
              var index = listofdata.indexOf(element);
              if (index !== -1) {
                listofdata.splice(index, 1);
              }
            }
          });

      e.target.mobileapp.checked
        ? listofdata.push("mobileapp,")
        : listofdata.forEach((element) => {
            if (element == "mobileapp,") {
              var index = listofdata.indexOf(element);
              if (index !== -1) {
                listofdata.splice(index, 1);
              }
            }
          });

      e.target.datacenter.checked
        ? listofdata.push("datacenter,")
        : listofdata.forEach((element) => {
            if (element == "datacenter,") {
              var index = listofdata.indexOf(element);
              if (index !== -1) {
                listofdata.splice(index, 1);
              }
            }
          });

      e.target.other.checked
        ? listofdata.push("other,")
        : listofdata.forEach((element) => {
            if (element == "other,") {
              var index = listofdata.indexOf(element);
              if (index !== -1) {
                listofdata.splice(index, 1);
              }
            }
          });

      let listsendeddata = listofdata.join("");

      let data = {
        name: e.target.name.value ? e.target.name.value : "empty",
        email: e.target.email.value ? e.target.email.value : "empty",
        phone: phone ? phone : "empty",
        message: e.target.message.value ? e.target.message.value : "empty",
        requestedser: listsendeddata ? listsendeddata : "empty",
      };

      let result = await contactServices(data);
      setTimeout(() => {
        if (result.id !== 0) {
          setLoading(false);
          setMessageSucces(true);
          e.target.name.value = "";
          e.target.email.value = "";
          e.target.message.value = "";
          e.target.other.checked = false;
          e.target.website.checked = false;
          e.target.uxdesign.checked = false;
          e.target.mobileapp.checked = false;
          e.target.project.checked = false;
          e.target.datacenter.checked = false;
          e.target.webapp.checked = false;
          setPhone(null);
        } else {
          setLoading(false);
          setMessageError(true);
        }
      }, 2000);
    } else {
      setFillColor("red");
      alert("Please complete all required fields.");
    }
  };

  return (
    <div>
      <Dimmer page active={loading}>
        <Loader />{" "}
      </Dimmer>
      <Navbar />

      <section
        style={{ marginTop: "150px", marginBottom: "50px" }}
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="150"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3 contac-column">
              <div className="card card-contact">
                <div className="card-body">
                  <h4 className="card-title text-contact">
                    <span>Get In Touch</span>
                  </h4>
                  <p className="card-text text-p">
                    We&#39;re here for you, Our friendly team is always here to
                    chat.
                  </p>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col">
                      <p className="text-contact">
                        <svg
                          className="icon-contact"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                        >
                          <path d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z"></path>
                        </svg>
                        Chat To Us
                      </p>

                      <div className="container">
                        <p className="text-p">
                          Chat with us anytime, our team helps you everywhere.
                        </p>
                        <p className="link-cp">info@algofact.tech</p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="row">
                    <div className="col">
                      <p className="text-contact">
                        <svg
                          className="icon-contact"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-64 0 512 512"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                        >
                          <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"></path>
                        </svg>
                        Offices
                      </p>
                      <div className="container ">
                        <p className="text-p">Come say hello at our office.</p>
                        <p className="link-cp">
                          Adana / Çukurova
                          <br />
                           Güzelyalı, Yalı Apartmanı, 81155. Sk. No : 4 Kat : 2 Daire 6, 01170 
                          <br />
                          Ankara/Çankaya
                          <br />
                         
                        </p>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  <br />
                  <div className="row">
                    <div className="col">
                      <p className="text-contact">
                        <svg
                          className="icon-contact"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                        >
                          <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"></path>
                        </svg>
                        Chat To Us
                      </p>
                      <div className="container">
                        <p className="text-p">Everyday 9:00-20:00</p>
                        <p className="link-cp">+ 90-532-379-62-64</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body p-5 mt-2">
                  <h4 className="card-title">
                    <span>Level Up Your Business</span>
                  </h4>
                  <h6 className="text-muted card-subtitle mb-2">
                    You can reach us anytime via{" "}
                    <strong>info@algofact.tech </strong>
                  </h6>
                  <form onSubmit={handleForm}>
                    <div className="div-contact">
                      <label className="form-label">Name</label>
                      <input     style={{borderColor:fillColor}} className="form-control" type="text" name="name" />
                    </div>
                    <div className="div-contact">
                      <label className="form-label">Email</label>
                      <input
                          style={{borderColor:fillColor}}
                        className="form-control"
                        type="text"
                        name="email"
                      />
                    </div>
                    <div className="div-contact">
                      <label className="form-label">Phone Number</label>

                      <PhoneInput
                        buttonStyle={{
                          backgroundColor: "#eef0f5",
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottomColor: fillColor,

                          borderColor: "black",
                        }}
                        inputStyle={{
                          backgroundColor: "#eef0f5",
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottomColor: fillColor,
                          width: "100%",
                          borderRadius: "2px",
                          borderColor: "black",
                        }}
                        country={"us"}
                        placeholder="Enter phone number"
                        onChange={(e) => {
                          setPhone(e);
                        }}
                        value={phone}
                      />
                    </div>
                    <div className="div-contact">
                      <label className="form-label">How can we help ?</label>
                      <textarea
                      style={{borderColor:fillColor}}
                        className="form-control"
                        name="message"
                      ></textarea>
                    </div>
                    <div className="div-contact">
                      <label className="form-label">Services</label>
                      <div className="row">
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="formCheck-1"
                              className="form-check-input"
                              type="checkbox"
                              name="website"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck-1"
                            >
                              Website Design
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="formCheck-2"
                              className="form-check-input"
                              type="checkbox"
                              name="uxdesign"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck-2"
                            >
                              UX Design
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="formCheck-3"
                              className="form-check-input"
                              type="checkbox"
                              name="project"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck-3"
                            >
                              Project Management
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="formCheck-4"
                              className="form-check-input"
                              type="checkbox"
                              name="webapp"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck-4"
                            >
                              Web Application
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="formCheck-5"
                              className="form-check-input"
                              type="checkbox"
                              name="mobileapp"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck-5"
                            >
                              Mobile Application
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="formCheck-6"
                              className="form-check-input"
                              type="checkbox"
                              name="datacenter"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck-6"
                            >
                              Data Center
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="formCheck-7"
                              className="form-check-input"
                              type="checkbox"
                              name="other"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="formCheck-7"
                            >
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-block send-button">
                      Send Us
                    </button>

                    {messageSucces ? (
                      <Message color="green">
                        Thank You for your message. Our Team will contact wit
                        you ASAP. Have a nice day!
                      </Message>
                    ) : (
                      <div></div>
                    )}
                    {messageError ? (
                      <Message color="red">
                        Sorry! There occur a error when sending your message.
                        Please try again or direct contact us +90-536-059-96-97
                      </Message>
                    ) : (
                      <div></div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
