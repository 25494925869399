import React from 'react'

import frame from '../../assets/images/sectframe/Comp-1_135.webp'

export default function SectionThree() {
  return (
    <div>
<section >
    <div className="row">
        <div className="col">
            
            <div  className='container'>

                <img  className='img-fluid' src={frame} alt='mobilappframe'/>

            </div>
            
            
            
            
            </div>
    
    </div>
</section>

    </div>
  )
}
