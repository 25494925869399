import React from "react";
import finance from "../../assets/images/finance1.webp";
import ecommerce from "../../assets/images/ecommerce.webp";
import business from "../../assets/images/business.webp";
import health from "../../assets/images/health.webp";
import external from "../../assets/images/external.webp";




export default function SectionFour() {
  return (
    <div>
      <section style={{marginTop:'10px',marginBottom:'15px'}}>

      <div className="text-white border rounded-0 border-0 d-xl-flex justify-content-xl-center back-color align-items-xl-center p-4 p-md-5" >
            <h2 className="fw-bold text-center text-white mb-0 text-style">Industries</h2>
        </div>
        <div>
          <div className="container">
            <div className="row" style={{marginBottom:'10px',marginTop:'10px'}}>
              <div className="col-md-4 col-in">
                <div className="cardo">
                  <img className="img-fluid" src={finance} alt='finance' />
                  <div className="info">
                    <h2 className="text-break">Finance & Accounting</h2>
                    <p>"Our company utilizes a finance and accounting software to efficiently manage and track financial transactions, budgeting, and forecasting to ensure accurate financial reporting and decision-making."</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-in">
                <div className="cardo">
                  <img className="img-fluid" src={business} alt='business' />
                  <div className="info">
                    <h2 className="text-break">Business Management</h2>
                    <p>"Our business management software has streamlined our operations and improved efficiency by allowing us to easily track and manage our financial data, project progress, and customer relationships."</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-in">
                <div className="cardo">
                  <img className="img-fluid" src={health} alt='health' />
                  <div className="info">
                    <h2 className="text-break">Medical & Health</h2>
                    <p>"Our Medical & Health software is designed to improve patient care and streamline healthcare organization's administrative processes, ultimately leading to a better overall experience for both patients and healthcare providers."</p>
                  </div>
                </div>
              </div>



            </div>




            <div className="row">
  

              <div className="col-md-6 col-in">
                <div className="cardo big-cardo">
                  <img className="img-fluid" src={external} alt='external'/>
                  <div className="info">
                    <h2 className="text-break">External & Domestic Trade</h2>
                    <p>"Our External and Domestic Trade software helps businesses streamline and optimize their import and export processes, providing a seamless experience for trading goods and services both domestically and internationally."</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-in">
                <div className="cardo big-cardo-2">
                  <img className="img-fluid"  src={ecommerce} alt='ecommerce' />
                  <div className="info">
                    <h2 className="text-break">E-Commerce</h2>
                    <p>"Our ecommerce software allows businesses to set up and manage an online store, process payments, and track inventory all in one platform, streamlining the process of selling products and services online."</p>
                  </div>
                </div>
              </div>


              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
