import React from 'react'

export default function Loader() {

    


  return (
    <div className='d-flex justify-content-center align-items-center' >

        <span className="loader "></span>

    </div>
  )
}
