import React from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css'
import '../src/assets/css/navbar.css'
import '../src/assets/css/footer.css'
import '../src/assets/css/sections.css'
import '../src/assets/css/about.css'
import '../src/assets/css/services.css'
import '../src/assets/css/contact.css'
import '../src/assets/css/loader.css'

import AOS from 'aos'





import App from './App';
AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

